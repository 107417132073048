<template>
  <div id="award-type-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>

        <v-row wrap>
          <v-col align="right" cols="12" md="12" lg="12">
            <v-btn
              @click.prevent="addRecordPrompt = true"
              color="#8950FC"
              elevation="30"
              rounded
              medium
              class="font-size-h6 px-3 mr-3 white--text"
            >
              &nbsp; Create &nbsp;
            </v-btn>
            <v-btn
              medium
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              rounded
              class="font-size-h6 px-3 mr-3 white--text"
              >&nbsp; Refresh &nbsp;
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>
      <v-card-text>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="confirmAlert"
        >
          <v-container class="py-0">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Award Type</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="AwardTypeOptions"
                  :rules="AwardTypeRules"
                  v-model="AwardType"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                :disabled="!valid1"
                @click.prevent="searchForm"
                :loading="SubmitFlag"
                elevation="30"
                rounded
                medium
                color="#1db954"
                class="font-size-h6 px-5 mr-3 white--text"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-container class="py-0" v-if="tableData1.length > 0">
          <br />
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected1"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :show-select="tableOptions1.ShowSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:[`item.ActiveStatusTxt`]="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:[`item.MemberImagePath`]="{ item }">
                  <img width="100" height="100" :src="item.MemberImagePath" />
                </template>
                <template v-slot:[`item.Actions`]="{ item }">
                  <v-tooltip bottom v-if="item.PreviewFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.QuestionsButtonFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        @click="addCriteriaQuestions(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-note-plus
                      </v-icon>
                    </template>
                    <span> Questions </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.EditButtonFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="editAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DeleteButtonFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="#ff0000"
                        @click="deleteAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span> Delete </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br />
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent max-width="75%">
      <judging-criteria-create
        @hideAddRecordPrompt="hideAddRecordPrompt($event)"
        :recordData="recordData"
        v-if="addRecordPrompt"
      ></judging-criteria-create>
    </v-dialog>

    <v-dialog v-model="editRecordPrompt" persistent max-width="75%">
      <judging-criteria-edit
        :editRecordPrompt="editRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt($event)"
        :CriteriaId="recordData.JudgingCriteriaId"
        v-if="editRecordPrompt"
      ></judging-criteria-edit>
    </v-dialog>
  </div>
</template>
    
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import JudgingCriteriaCreate from "@/view/pages/erp/jci-award/judging-criteria/JudgingCriteriaCreate.vue";
import JudgingCriteriaEdit from "@/view/pages/erp/jci-award/judging-criteria/JudgingCriteriaEdit.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    JudgingCriteriaCreate,
    JudgingCriteriaEdit,
  },
  data() {
    return {
      valid1: true,
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      SelectFlag: true,
      DeleteFlag: false,
      SubmitFlag: false,

      alert: {},

      valid: true,

      RoleId: "",

      ResultFlag: 0,

      AwardTypeRules: [(v) => !!v || "Award Type is required"],
      AwardType: {},
      AwardTypeOptions: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      recordData: {},

      dialog: false,
      dialogDelete: false,
      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      TotalMembers: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
      selectedData: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getAwardTypeOptions();
      }
    },
    AwardType: function () {
      this.rows = [];
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
      console.log("watch AwardType");
      var temp_code = this.AwardType;
      console.log("temp_code=" + temp_code);
      var idx = this.AwardTypeOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var tr = this.AwardTypeOptions[idx];
        console.log({ tr });
        this.recordData = tr;
      }
    },
    AwardTypeOptions: function () {
      console.log("watch AwardTypeOptions");
      this.AwardTypeOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.searchForm();
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewAlert(tr) {
      console.log("previewAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.recordData = tr;
        this.previewRecordPrompt = true;
      } else {
        var message = "Kindly select one record to preview";
        this.errorMessage(message);
      }
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.searchForm();
      }
    },
    editAlert(tr) {
      console.log("editAlert called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.recordData = tr;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    addCriteriaQuestions(tr) {
      console.log("addCriteriaQuestions called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.JudgingCriteriaId = tr.JudgingCriteriaId;
        var JudgingCriteriaId = this.JudgingCriteriaId;
        console.log({ JudgingCriteriaId });
        this.$router.push(
          "/jci-award/judging-criteria-questions/?id=" + JudgingCriteriaId
        );
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "judging_criteria",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getAwardTypeOptions() {
      console.log("getAwardTypeOptions called");
      var selectbox1_source = "AwardType";
      var selectbox1_destination = "AwardTypeOptions";
      var selectbox1_url = "api/award-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.tableData1 = [];
        this.selected1 = [];
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/judging-criteria/lists";
        var upload = {
          UserInterface: 1,
          AwardType: this.AwardType,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var table_content = [];
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.ProgessStart = 0;
            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = records.TableOptions;

            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;

              thisIns.toast("success", output);
            } else {
              console.log("error=" + output);
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
          });
      } else {
        var message = "Kindy fill required fields and then submit";
        this.toast("error", message);
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert();
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/>Award Type <b> " + tr.AwardTypeTitle + "</b>";
        htmlTxt +=
          "<br/>Criteria Detail <b> " + tr.JudgingCriteriaDetail + "</b>";
        htmlTxt +=
          "<br/>Criteria Score <b> " + tr.JudgingCriteriaScore + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";

        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRecord(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteRecord(tr) {
      console.log("deleteRecord called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });

      if (n1 > 0) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/judging-criteria/destroy";
        var upload = {
          UserInterface: 1,
          CriteriaId: tr.JudgingCriteriaId,
          CriteriaDetail: tr.JudgingCriteriaDetail,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one member to delete";
        this.sweetAlert("error", message, false);
      }
    },
    addRecordPrompt(tr) {
      console.log(" addRecordPrompt called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.selectedData = tr;
      } else {
        var message = "Kindly select one Award to create";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
</style>